import { useQuery } from "@tanstack/react-query";
import "./aboutsection.css"
import { makeRequest } from "../../axios";
import { EditorState, convertFromRaw } from "draft-js";
import { Link } from "react-router-dom";
import MediaQuery from "react-responsive";
import baseUrl from "../../baseUrl"

export default function AboutSection(props){
    const profilePicQuery = useQuery(["profilePics"], () =>
        makeRequest.get("https://"+baseUrl+"/api/getprofilepics", {mode: "cors"}).then((res) => {
            return res.data;
        })
     );

     //let leftPic, rightPic = ""
     //profilePicQuery.data ? [leftPic, rightPic] = profilePicQuery.data : [leftPic, rightPic] = ""
     let leftPic = ""
     profilePicQuery.data ? leftPic = profilePicQuery.data : leftPic = ""

    const infoQuery = useQuery(["info"], () =>
        makeRequest.get("https://"+baseUrl+"/api/info", {mode: "cors"}).then((res) => {
            return res.data;
        })
    );

    
    const infoData = infoQuery.data?.map((data) => data[1])

    let editorState = EditorState.createEmpty()
    
    let infoText = ""

    if(infoData){
        const contentState = convertFromRaw(JSON.parse(infoData))
        editorState = EditorState.createWithContent(contentState)
        infoText = editorState.getCurrentContent().getPlainText('\u0001').substring(0, 328)
    }

    return (
        <div className="aboutSection">
            <MediaQuery maxWidth={600}>
                <div className="mobileAboutSectionPreview">
                    {props.detailed==true ? 
                    <><p>Bakgrund</p><div className="mobileAboutSectionPreviewText">
                            {infoText}
                        </div><Link className="mobileAboutSectionLink" to={"/about"}>
                                Läs mer
                            </Link></>
                    : null}
                    <div className="mobileAboutSectionProfiles">
                        <div className="mobileAboutSectionProfile">
                            <img className="mobileAboutSectionPreviewPic" src={"/profile_pictures/"+leftPic} alt="Not found"/>
                            <p className="mobileAboutSectionPreviewName">Claes Runbalk</p>
                            <p className="mobileAboutSectionPreviewEmail">crunbalk@gmail.com</p>
                        </div>
                        {/*
                        <div className="mobileAboutSectionProfile">
                            <img className="mobileAboutSectionPreviewPic" src={"/profile_pictures/"+rightPic} alt="Not found"/>
                            <p className="mobileAboutSectionPreviewName">Hans Runbalk</p>
                            <p className="mobileAboutSectionPreviewEmail">hansrunbalk@gmail.com</p>
                        </div>
                        */}
                    </div>
                </div>
               
            </MediaQuery>

            <MediaQuery minWidth={601} maxWidth={1000}> {/* TABLET */}
                <div className="mobileAboutSectionPreview">
                    {props.detailed==true ? 
                    <><p>Bakgrund</p><div className="mobileAboutSectionPreviewText">
                            {infoText}
                        </div><Link className="mobileAboutSectionLink" to={"/about"}>
                                Läs mer
                            </Link></>
                    : null}
                    <div className="mobileAboutSectionProfiles">
                        <div className="mobileAboutSectionProfile">
                            <img className="mobileAboutSectionPreviewPic" src={"/profile_pictures/"+leftPic} alt="Not found"/>
                            <p className="mobileAboutSectionPreviewName">Claes Runbalk</p>
                            <p className="mobileAboutSectionPreviewEmail">crunbalk@gmail.com</p>
                        </div>
                        {/*
                        <div className="mobileAboutSectionProfile">
                            <img className="mobileAboutSectionPreviewPic" src={"/profile_pictures/"+rightPic} alt="Not found"/>
                            <p className="mobileAboutSectionPreviewName">Hans Runbalk</p>
                            <p className="mobileAboutSectionPreviewEmail">hansrunbalk@gmail.com</p>
                        </div>
                        */}
                    </div>
                </div>
            </MediaQuery>

            <MediaQuery minWidth={1001} maxWidth={1599}>
                <div className="aboutSectionPreview">
                    <div className="aboutSectionPreviewLeft">
                        <p>Bakgrund</p>
                        <div className="aboutSectionPreviewText">
                            {infoText}
                        </div>
                        
                        <Link className="aboutSectionLink" to={"/about"}>
                            Läs mer
                        </Link>
                    </div>
                    <div className="aboutSectionPreviewRight">
                        <div className="aboutSectionPreviewProfile">
                            <img className="aboutSectionPreviewPic" src={"/profile_pictures/"+leftPic} alt="Not found"/>
                            <p className="aboutSectionPreviewName">Claes Runbalk</p>
                            <p className="aboutSectionPreviewEmail">crunbalk@gmail.com</p>
                        </div>
                        {/*
                        <div className="aboutSectionPreviewProfile">
                            <img className="aboutSectionPreviewPic" src={"/profile_pictures/"+rightPic} alt="Not found"/>
                            <p className="aboutSectionPreviewName">Hans Runbalk</p>
                            <p className="aboutSectionPreviewEmail">hansrunbalk@gmail.com</p>
                        </div>
                        */}
                    </div>
                </div>
            </MediaQuery>

            <MediaQuery minWidth={1600}>
                <div className="aboutSectionPreview">
                    <div className="aboutSectionPreviewLeft">
                        <p>Bakgrund</p>
                        <div className="aboutSectionPreviewText">
                            {infoText}
                        </div>
                        
                        <Link className="aboutSectionLink" to={"/about"}>
                            Läs mer
                        </Link>
                    </div>
                    <div className="aboutSectionPreviewRight">
                        <div className="aboutSectionPreviewProfile">
                            <img className="aboutSectionPreviewPic" src={"/profile_pictures/"+leftPic} alt="Not found"/>
                            <p className="aboutSectionPreviewName">Claes Runbalk</p>
                            <p className="aboutSectionPreviewEmail">crunbalk@gmail.com</p>
                        </div>
                        {/*
                        <div className="aboutSectionPreviewProfile">
                            <img className="aboutSectionPreviewPic" src={"/profile_pictures/"+rightPic} alt="Not found"/>
                            <p className="aboutSectionPreviewName">Hans Runbalk</p>
                            <p className="aboutSectionPreviewEmail">hansrunbalk@gmail.com</p>
                        </div>
                        */}
                    </div>
                </div>
            </MediaQuery>
        </div>
    )
}