import Topbar from "./components/topbar/Topbar"
import Header from "./components/header/Header"
import LatestSection from "./components/latestsection/LatestSection";
import Latest from "./components/latest/Latest";
import FavoriteSection from "./components/favoritessection/FavoriteSections";
import AboutSection from "./components/aboutsection/AboutSection";
import Home from "./Pages/Home/Home";
import Blues from "./Pages/Blues/Blues";
import Rock from "./Pages/Rock/Rock"
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from 'react-router-dom'
import Add from "./Pages/Admin/Add/Add";
import Register from "./Pages/Admin/Register/Register";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "./context/authContext";
import Login from "./Pages/Admin/Login/Login";
import {QueryClient, QueryClientProvider, useQuery} from '@tanstack/react-query'
import SingleRecord from "./Pages/SingleRecord/SingleRecord";
import Records from "./components/records/Records";
import About from "./Pages/About/About";
import AboutSingle from "./Pages/AboutSingle/AboutSingle";
import EditReview from "./Pages/EditReview/EditReview";
import { makeRequest } from "./axios";
import SearchResult from "./Pages/SearchResult/SearchResult";
import EditAbout from "./Pages/EditAbout/EditAbout";
import Profile from "./Pages/Profile/Profile";
import baseUrl from "./baseUrl"
import "./app.css"


function App() {
  
  const {user} = useContext(AuthContext)

  const queryClient = new QueryClient()

  //const [totalVisitors, setTotalVisitors] = useState(0);

  console.log(baseUrl)
  //useEffect(() => {
    // Call the PHP API to increment and fetch the total visitors
    
    /*fetch('https://kavemark.se/api/total_visitors.php')
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setTotalVisitors(data.total_visitors);
          console.log("success")
        } else {
          console.error(data.message);
        }
      })
      .catch((error) => console.error('Error fetching total visitors:', error));
  }, []);*/

  /*const profilePicQuery = useQuery(["profilePics"], () =>
    makeRequest.get("https://"+baseUrl+"/api/total_visitors", {mode: "cors"}).then((res) => {
        return res.data;
    })
 );*/
  
  return (
    <div className="App">
    <QueryClientProvider client={queryClient}>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} /> 
          <Route path="/blues" element={<Records genre={"blues"} page={1} />} />
          <Route path="/rock" element={<Records genre={"rock"} page={1} />} />
          <Route path="/jazz" element={<Records genre={"jazz"} page={1} />} />
          <Route path="/annat" element={<Records genre={"annat"} page={1} />} />


          <Route path="/about" element={<About />} />
          <Route path="/about/:name" element={<AboutSingle />} />

          {user &&<Route path="/add" element={<Add />} />}
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />

          <Route path="/:genre/:record" element={<SingleRecord />} />
          <Route path="/edit/:record" element={<EditReview />} />

          <Route path="/about/edit" element={<EditAbout />} />

          <Route path="/search" element={<SearchResult />} />

          {user &&<Route path="/profile" element={<Profile />} />}

        </Routes>
        
        
      </Router>
    </QueryClientProvider>
    </div>
  );
}

export default App;
