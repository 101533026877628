import React from 'react';
import classnames from 'classnames';
import { usePagination, DOTS } from './usePagination';
import './pagination.css';

import NavigationLeftGray from "../../assets/icons/nav-arrow-left-gray.svg";
import NavigationRightGray from "../../assets/icons/nav-arrow-right-gray.svg";

import NavigationLeftGold from "../../assets/icons/nav-arrow-left-gold.svg";
import NavigationRightGold from "../../assets/icons/nav-arrow-right-gold.svg";

import NavigationRightBlack from "../../assets/icons/nav-arrow-right-black.svg";
import NavigationLeftBlack from "../../assets/icons/nav-arrow-left-black.svg";



const Pagination = props => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  console.log("Pagination::")
  console.log(paginationRange)
  console.log("Pagination::currentpage " + currentPage)
  console.log("Pagination::totalcount " + totalCount)

  const handleRightHover = (event) => {
    event.target.src = NavigationRightBlack; // Set the source when hovered
  };
  
  const handleRightHoverOut = (event) => {
    event.target.src = NavigationRightGold; // Set the source back when not hovered
  };
  
  const handleLeftHover = (event) => {
    
      event.target.src = NavigationLeftBlack; // Set the source when hovered
  };
  
  const handleLeftHoverOut = (event) => {
    
      event.target.src = NavigationLeftGold; // Set the source back when not hovered
  };

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  console.log("Pagination::HERE")
  return (
    <ul
      className={classnames('pagination-container', { [className]: className })}
    >
      <li
        className={classnames('pagination-item arrow-left', {
          disabled: currentPage === 1
        })}
        onClick={onPrevious}
      >
        {/*<div className="arrow left" />*/}
        <img 
          src={currentPage == 1 ? NavigationLeftGray : NavigationLeftBlack}
        />
        
      </li>
      {paginationRange.map(pageNumber => {
        if (pageNumber === DOTS) {
          return <li className="pagination-item dots">&#8230;</li>;
        }

        return (
          <li key={pageNumber}
            className={classnames('pagination-item', {
              selected: pageNumber === currentPage
            })}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </li>
        );
      })}
      <li
        className={classnames('pagination-item arrow-right', {
          disabled: currentPage === lastPage
        })}
        onClick={onNext}
      >
        {/*<div className="arrow right" />*/}
        <img 
          src={currentPage == lastPage ? NavigationRightGray : NavigationRightBlack} 
          
        />
      </li>
    </ul>
  );
};

export default Pagination;
