import "./footer.css"
import blockazzLogo from "../../assets/images/blockazz_logo.png"
import logo from "../../assets/images/logo.png"
import { Link } from "react-router-dom"
import MediaQuery from "react-responsive"

export default function Footer() {
    return (
        <div className="footer">
            <MediaQuery maxWidth={600}>
            <Link className="mobileFooterLogo" to="/"> <img src={logo}  /> </Link>
            <div className="mobileFooterInfo">
                <p className="mobileFooterHeader">Blockazz Records</p>
                <p className="mobileFooterText">

                    Blockazz Records är sidan som recenserar och tipsar om både nya och äldre skivor
                    inom Blues, Rock, Jazz och andra genrer. Samtliga skivor kommer från egen samling. Syftet är att 
                    inspirera och göra det lättare för andra att hitta bra musik.
                    <br/>
                    <br/>
                    

                </p>
                <div className="mobileFooterHorizontalLine"></div>
                <p className="mobileFooterContactHeader">Kontakt</p>
                <p className="mobileFooterEmail">crunbalk@gmail.com</p>
                
            </div>
            <div className="mobileFooterCopyright">
                <p>© 2025 Blockazz Records. All Rights Reserved.</p>
            </div>

            </MediaQuery>

            <MediaQuery minWidth={601} maxWidth={1000}> {/* TABLET */}
                <Link className="mobileFooterLogo" to="/"> <img src={logo}  /> </Link>
                <div className="mobileFooterInfo">
                    <p className="mobileFooterHeader">Blockazz Records</p>
                    <p className="mobileFooterText">
                    Blockazz Records är sidan som recenserar och tipsar om både nya och äldre skivor
                    inom Blues, Rock, Jazz och andra genrer. Samtliga skivor kommer från egen samling. Syftet är att 
                    inspirera och göra det lättare för andra att hitta bra musik.
                    <br/>
                    <br/>
                    </p>
                    <div className="mobileFooterHorizontalLine"></div>
                    <p className="mobileFooterContactHeader">Kontakt</p>
                    <p className="mobileFooterEmail">crunbalk@gmail.com</p>
                    
                </div>
                <div className="mobileFooterCopyright">
                    <p>© 2025 Blockazz Records. All Rights Reserved.</p>
                </div>
            </MediaQuery>

            <MediaQuery minWidth={1001} maxWidth={1599}>
                <Link className="footerLogo" to="/"> <img src={logo}  /> </Link>
                <p className="footerInfoHeader">Blockazz Records</p>
                <div className="footerInfo">
                Blockazz Records är sidan som recenserar och tipsar om både nya och äldre skivor
                    inom Blues, Rock, Jazz och andra genrer. Samtliga skivor kommer från egen samling. Syftet är att 
                    inspirera och göra det lättare för andra att hitta bra musik.
                    <br/>
                    <br/>
                    
                </div>
                <div className="mobileFooterHorizontalLine"></div>
                <div className="footerContact">
                    <p className="footerContactHeader">Kontakt</p>
                    <div className="footerEmails">
                        <p className="footerEmail">crunbalk@gmail.com</p>
                    </div>
                </div>
                
                <div className="footerBottomSection">
                    <p className="footerCopyright">
                        © 2025 Blockazz Records. All Rights Reserved.
                    </p>
                </div>
            </MediaQuery>

            <MediaQuery minWidth={1600}>
                <Link className="footerLogo" to="/"> <img src={logo}  /> </Link>
                <p className="footerInfoHeader">Blockazz Records</p>
                <div className="footerInfo">
                Blockazz Records är sidan som recenserar och tipsar om både nya och äldre skivor
                    inom Blues, Rock, Jazz och andra genrer. Samtliga skivor kommer från egen samling. Syftet är att 
                    inspirera och göra det lättare för andra att hitta bra musik.
                    <br/>
                    <br/>
                    
                    
                </div>
                <div className="mobileFooterHorizontalLine"></div>
                <div className="footerContact">
                    <p className="footerContactHeader">Kontakt</p>
                    <div className="footerEmails">
                        
                        <p className="footerEmail">crunbalk@gmail.com</p>
                        
                        
                    </div>
                </div>
                
                <div className="footerBottomSection">
                    <p className="footerCopyright">
                        © 2025 Blockazz Records. All Rights Reserved.
                    </p>
                </div>
            </MediaQuery>
        </div>
    )
}